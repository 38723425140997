<template>
  <v-menu location="bottom" transition="slide-y-transition" offset="15">
    <template v-slot:activator="{ props }">
      <v-list-item
        v-bind="{ ...$attrs, ...props }"
        rounded="lg"
        class="w-full px-2 hover:bg-[rgba(100,100,100,0.25)]"
        link
      >
        <template #prepend>
          <v-avatar size="30" color="white">
            <logo size="25" color="indigo" lucid />
          </v-avatar>
        </template>
        <template #title>
          <div class="font-semibold text-3.4" :title="currentName">
            {{ currentName }}
          </div>
        </template>
        <template #append>
          <!-- <Icon class="ml-2" name="oui:arrow-down" size="15" /> -->
          <v-icon class="ml-2">expand_more</v-icon>
        </template>
      </v-list-item>
    </template>
    <v-list class="pa-0" min-width="270">
      <div class="pa-2">
        <v-list-item
          v-for="(workspace, i) in workspaces"
          :key="i"
          :title="workspace.name"
          :subtitle="workspace.code"
          rounded="lg"
          class="px-2"
          @click="switchTo(workspace.code)"
          :disabled="workspace.active"
        >
          <template #prepend>
            <user-avatar
              :avatar="workspace.avatar"
              :name="workspace.name"
              color="primary"
              size="30"
            />
          </template>
          <template #append>
            <Icon v-if="workspace.active" name="jam:check" />
          </template>
        </v-list-item>
      </div>
      <v-divider />
      <div class="pa-2">
        <v-list-item
          title="New workspace"
          rounded="lg"
          class="px-2"
          :to="{ name: 'onboarding-new-store' }"
        >
          <template #prepend>
            <Icon name="jam:store" class="mr-2" />
          </template>
          <template #append>
            <Icon name="jam:plus" />
          </template>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const { workspace, workspaces, switchTo } = useWorkspace();
    const currentName = computed(() => workspace.value?.name || "Workspace");

    return {
      currentName,
      workspaces,
      switchTo,
    };
  },
});
</script>
